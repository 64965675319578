import React from 'react';

const className = 'c-IconV2-NasIOLogoWithText';

const NasIOLogoWithText = ({
  width = '110',
  height = '25',
  opacity = '0.5',
  fill = 'black',
  customClassNames
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 110 25"
      fill="none"
      className={`${className} ${customClassNames}`}>
      <path
        d="M14.8484 5.35681C14.8484 6.93405 13.5698 8.21264 11.9926 8.21264C10.4153 8.21264 9.13672 6.93405 9.13672 5.35681C9.13672 3.77958 10.4153 2.50098 11.9926 2.50098C13.5698 2.50098 14.8484 3.77958 14.8484 5.35681Z"
        fill={fill}
        fillOpacity={opacity}
      />
      <path
        d="M2.76505 14.2451C1.74498 13.225 1.74498 11.5712 2.76505 10.5511C3.78511 9.53114 5.43895 9.53114 6.45903 10.5511L11.9994 16.0915L17.5398 10.5511C18.5598 9.53113 20.2137 9.53113 21.2337 10.5511C22.2538 11.5713 22.2538 13.225 21.2337 14.2452L13.8942 21.5848C13.8787 21.6011 13.863 21.6172 13.847 21.6332C13.3163 22.1639 12.614 22.4185 11.9186 22.3969C11.904 22.3965 11.8893 22.3959 11.8747 22.3952C11.2482 22.3656 10.6302 22.1116 10.1517 21.6332C10.1355 21.617 10.1195 21.6006 10.1038 21.584L2.76505 14.2451Z"
        fill={fill}
        fillOpacity={opacity}
      />
      <path
        d="M39.6966 4.68359H44.153C44.4116 4.68359 44.5347 4.80698 44.5347 5.06609V21.7603C44.5347 21.9947 44.4116 22.1181 44.153 22.1181H39.7951C39.5735 22.1181 39.4012 22.0071 39.2657 21.785L34.8831 13.9252V21.7603C34.8831 21.9947 34.76 22.1181 34.5015 22.1181H30.0327C29.8357 22.1181 29.688 21.9454 29.688 21.7603V5.06609C29.688 4.80698 29.7987 4.68359 30.0327 4.68359H34.4029C34.6862 4.68359 34.8831 4.79463 34.9816 5.01673L39.3395 12.8641V5.06609C39.3395 4.86867 39.5366 4.68359 39.6966 4.68359Z"
        fill={fill}
        fillOpacity={opacity}
      />
      <path
        d="M62.9886 22.1181H57.9903C57.7319 22.1181 57.5596 22.0071 57.4611 21.785L56.7102 19.1322H51.9705L51.2072 21.785C51.1087 22.0071 50.9364 22.1181 50.6779 22.1181H45.692C45.4951 22.1181 45.3966 21.97 45.4581 21.785L51.158 5.01673C51.2565 4.79464 51.4534 4.68359 51.7612 4.68359H56.9071C57.2149 4.68359 57.4119 4.79464 57.5103 5.01673L63.2102 21.785C63.2717 21.9454 63.1855 22.1181 62.9886 22.1181ZM52.9799 15.517H55.7006L54.3341 10.7172L52.9799 15.517Z"
        fill={fill}
        fillOpacity={opacity}
      />
      <path
        d="M63.333 9.94002C63.333 6.57157 65.869 4.30127 69.7099 4.30127C71.4949 4.30127 73.7848 5.04158 75.3606 6.12737C75.5945 6.27544 75.6683 6.4235 75.5576 6.57157L73.9694 10.1374C73.8833 10.4089 73.7355 10.4829 73.514 10.3472C72.1352 9.55753 70.9533 9.11334 69.9685 9.02697C69.1929 9.03929 68.6759 9.40946 68.6759 9.903C68.6759 10.3349 69.2914 10.7668 70.5225 11.174C71.7535 11.5811 72.8984 12.1733 73.9572 12.9506C75.0282 13.728 75.8161 15.1469 75.8161 16.8866C75.8284 20.2551 73.2062 22.5007 69.3283 22.5007C67.2108 22.5007 64.9704 21.8344 63.7392 20.835C63.5177 20.6623 63.4438 20.5142 63.53 20.3785L65.1181 16.8126C65.2043 16.5412 65.352 16.4794 65.5736 16.6151C66.8539 17.3431 68.0235 17.7379 69.0821 17.8243C69.8823 17.8243 70.4117 17.405 70.4117 16.9113C70.3993 16.4795 69.7839 16.0476 68.5528 15.6158C67.3217 15.1963 66.1891 14.604 65.1673 13.8514C64.1333 13.074 63.333 11.655 63.333 9.94002Z"
        fill={fill}
        fillOpacity={opacity}
      />
      <path
        d="M79.5054 22.5008C80.7238 22.5008 81.7115 21.5109 81.7115 20.2897C81.7115 19.0686 80.7238 18.0786 79.5054 18.0786C78.287 18.0786 77.2993 19.0686 77.2993 20.2897C77.2993 21.5109 78.287 22.5008 79.5054 22.5008Z"
        fill={fill}
        fillOpacity={opacity}
      />
      <path
        d="M89.0893 19.5526C89.0893 21.1808 87.7723 22.5007 86.1478 22.5007C84.5234 22.5007 83.2065 21.1808 83.2065 19.5526V7.76012C83.2065 6.13192 84.5234 4.81201 86.1478 4.81201C87.7723 4.81201 89.0893 6.13192 89.0893 7.76012V19.5526Z"
        fill={fill}
        fillOpacity={opacity}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.233 13.6564C108.233 18.5409 104.282 22.5007 99.4088 22.5007C94.5347 22.5007 90.585 18.5409 90.585 13.6564C90.585 8.77175 94.5347 4.81201 99.4088 4.81201C104.282 4.81201 108.233 8.77175 108.233 13.6564ZM103.495 13.4614C103.495 15.7293 101.661 17.5677 99.3983 17.5677C97.1355 17.5677 95.3013 15.7293 95.3013 13.4614C95.3013 11.1935 97.1355 9.35509 99.3983 9.35509C101.661 9.35509 103.495 11.1935 103.495 13.4614Z"
        fill={fill}
        fillOpacity={opacity}
      />
    </svg>
  );
};

export default NasIOLogoWithText;

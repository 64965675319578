import { fetchCommunityLandingPageData } from '@/services/community/communityPageService';
import { useEffect, useState } from 'react';

const useCommunityLandingPageData = ({ communitySlug, initialValue }) => {
  const [loading, setLoading] = useState(false);

  const [communityLandingPageData, setCommunityLandingData] =
    useState(initialValue);

  const getCommunityLandingPageData = async (communitySlug) => {
    setLoading(true);
    const communityPublicPageDataResponse =
      await fetchCommunityLandingPageData({ communitySlug });
    const communityPublicPageData =
      communityPublicPageDataResponse?.data?.communityData || null;
    setCommunityLandingData(communityPublicPageData);

    setLoading(false);
  };

  useEffect(() => {
    getCommunityLandingPageData(communitySlug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communitySlug]);

  return {
    loading,
    communityLandingPageData,
    mutate: () => getCommunityLandingPageData(communitySlug)
  };
};

export default useCommunityLandingPageData;

import { LazyMotion, domMax, m } from 'framer-motion';
import Link from 'next/link';
import React from 'react';

import Icon from '../npl/Icon';

const AnimatedTabLinks = ({
  tabs,
  tabOnClick,
  activeTabId,
  showHoveStrike = false
}) => {
  const shownTabsLength = tabs?.filter((tab) => tab.show)?.length;

  return (
    <LazyMotion features={domMax} strict>
      <div className="c-AnimatedTabLinks flex w-full">
        <div
          className={`flex w-full ${
            shownTabsLength < 5 ? 'justify-center' : 'md:justify-center'
          }`}>
          {tabs.map((tab) => {
            const { id, icon, label, link, eventId, show = true } = tab;
            const isActive = id === activeTabId;
            if (show) {
              return (
                <Link
                  onClick={() => tabOnClick(eventId)}
                  key={id}
                  className={`group relative flex justify-center px-8 py-16 text-label-md font-medium text-npl-text-icon-on-light-surface-primary ${
                    !isActive ? 'opacity-50' : ''
                  }`}
                  href={link}
                  role="button"
                  tabIndex={0}>
                  <p className="px-4">
                    {icon && (
                      <span className="mr-8">
                        <Icon name={icon} width={16} height={16} />
                      </span>
                    )}
                    {label}
                  </p>

                  {showHoveStrike && !isActive && (
                    <div className="absolute bottom-0 hidden h-2 w-full animate-fadeInLibrary rounded-t-8 bg-npl-neutral-light-solid-7 transition-all group-hover:block"></div>
                  )}
                  {isActive && (
                    <m.div
                      layoutId="activeIndicator-line"
                      className="absolute bottom-0 h-2 w-full rounded-t-8 bg-npl-text-icon-on-light-surface-primary"
                      transition={{
                        type: 'spring',
                        stiffness: 300,
                        damping: 30
                      }}
                    />
                  )}
                </Link>
              );
            }
          })}
        </div>
      </div>
    </LazyMotion>
  );
};

export default AnimatedTabLinks;

import config from '@/utility/config';
import { getQueryStrFromObj } from '@/utility/urlHelpers';

import { getAxiosErrorMsg } from '../helpers';
import authorizedRequest from '../helpers/authorizedRequest';
import { axiosFetcher } from '../helpers/swr';
import unAuthorizedRequest from '../helpers/unAuthorizedRequest';

const { communityApiBasePath } = config;

export const communityLandingPagePrefetch = async ({
  communityId,
  communitySlug,
  token
}) => {
  try {
    const path = communityId
      ? `${communityApiBasePath}/api/v1/community-landing-page/prefetch?communityId=${communityId}`
      : `${communityApiBasePath}/api/v1/community-landing-page/prefetch?communitySlug=${communitySlug}`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    if (token) {
      options.headers['Authorization'] = 'Bearer ' + token;
    }

    // const { data, error } = await authorizedRequest(path, options);
    const { data, error } = await unAuthorizedRequest(path, options);

    if (error) {
      throw new Error(error);
    }

    return data;
  } catch (e) {
    return { error: e.message };
  }
};

export const fetchCommunityLandingPageData = async ({
  communitySlug,
  token = null
}) => {
  try {
    const path = `${communityApiBasePath}/api/v1/community-landing-page?communitySlug=${communitySlug}`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    if (token) {
      options.headers['Authorization'] = 'Bearer ' + token;
    }

    const { data, error } = await authorizedRequest(path, options);

    if (error) {
      throw new Error(error);
    }

    return data;
  } catch (e) {
    return { error: e.message };
  }
};

export const fetchCommunityProducts = async ({
  communityId,
  pageNo,
  pageSize,
  type
}) => {
  const queryParamsStr = getQueryStrFromObj({ pageNo, pageSize, type });
  const path = `${communityApiBasePath}/api/v1/community-landing-page/${communityId}/products?${queryParamsStr}`;
  return axiosFetcher(path);
};

export const fetchCommunityMembersSummary = async (communityId) => {
  const path = `${communityApiBasePath}/api/v1/community-landing-page/${communityId}/member/summary`;
  const { data, error } = await axiosFetcher(path);

  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }

  return { data, error: null };
};

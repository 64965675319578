import classNames from 'classnames';
import React, { FC, ReactNode, useState } from 'react';

import { CommunityInfoType } from '@/features/Challenges/types';

import { useAuthContext } from '@/contexts/AuthContext';

import useUserCommunitiesData from '@/hooks/useUserCommunitiesData';

import AuthNavBar from './AuthNavbar';

type AuthNavbarPageLayoutProps = {
  children:
    | ReactNode
    | ((
        userCommunitiesData: unknown,
        setHasScrollPastBanner: (hasScrolledPastBanner: boolean) => void,
        hasScrolledPastBanner: boolean
      ) => ReactNode);
  communityInfo: CommunityInfoType;
  isCommunityPageView?: boolean;
};

const AuthNavbarPageLayout: FC<AuthNavbarPageLayoutProps> = ({
  communityInfo,
  children,
  isCommunityPageView = false
}) => {
  const { isLoggedIn } = useAuthContext();
  const [hasScrolledPastBanner, setHasScrollPastBanner] = useState(false);

  const userCommunitiesData = useUserCommunitiesData({
    communityInfo,
    isLoggedIn
  });

  const {
    userCommunities,
    isPendingApproval,
    isCommunityAdmin,
    isCommunityMember,
    openSignUpModal,
    isUserCommunitiesFetched,
    joinAsAffiliateProps
  } = userCommunitiesData;

  return (
    <>
      {isLoggedIn && (
        <div
          className={classNames(
            'top-0 z-over-intercom flex h-64 w-full items-center px-12',
            // if user is member, navbar will always be white
            // If user scroll past banner, its always white
            // isCommunityPageView = not on /home || /, then show whiteBG
            {
              'bg-white-default sticky':
                hasScrolledPastBanner ||
                isCommunityMember ||
                !isCommunityPageView

            }
          )}>
          <div className="flex-grow">
            <AuthNavBar
              communityInfo={communityInfo}
              userCommunities={userCommunities}
              isCommunityAdmin={isCommunityAdmin}
              isCommunityMember={isCommunityMember}
              isPendingApproval={isPendingApproval}
              openSignUpModal={openSignUpModal}
              joinAsAffiliateProps={joinAsAffiliateProps}
            />
          </div>
        </div>
      )}
      {!isUserCommunitiesFetched && !isLoggedIn && (
        <div className="h-64"></div>
      )}
      {typeof children === 'function'
        ? children(
            userCommunitiesData,
            setHasScrollPastBanner,
            hasScrolledPastBanner
          )
        : children}
    </>
  );
};

export default AuthNavbarPageLayout;

import axios from 'axios';
import protectedAxiosRequest from './protectedAxiosRequest';
import Auth from '@/modules/Auth';

// export const axiosFetcher = (url) =>
//   axios.get(url).then((res) => res.data);

export const axiosFetcher = (url) => {
  const token = Auth.getUserToken();
  const config = token
    ? { headers: { Authorization: `Bearer ${token}` } }
    : {};

  return axios
    .get(url, config)
    .then((res) => res.data)
    .catch((error) => {
      return { error };
    });
};

export const protectedAxiosFetcher = (url) =>
  protectedAxiosRequest
    .get(url)
    .then((res) => res.data)
    .catch((error) => {
      return { error };
    });

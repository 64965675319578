import { trackGAEvent } from '@/utility/analytics';
import { useEffect } from 'react';

const useTrackTabPageView = ({ eventName, communityId }) => {
  // Register product tab page view analytics
  useEffect(() => {
    trackGAEvent(eventName, {
      communityId
    });
  }, [communityId, eventName]);

  return;
};

export default useTrackTabPageView;

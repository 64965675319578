import Icon from '@/components/npl/Icon';
import NPLLinkButton, {
  LINK_BTN_HIERARCHY
} from '@/components/npl/NPLLinkButton';

const PortalBanner = ({
  onClick,
  onClose,
  ctaText,
  text,
  renderSubText,
  bgColor,
  textColor,
  iconColor,
  zIndex,
  showAlertIcon,
  buttonHierarchy,
  isPublicPage
}) => {
  return (
    <div
      className={`sticky top-[71px] flex h-[52px] w-full flex-row items-center justify-center py-12 md:py-16 ${
        bgColor ? bgColor : 'bg-npl-yellow-light-solid-5'
      } ${isPublicPage ? 'md:top-0' : 'md:top-64'}`}
      style={{ zIndex: zIndex ? zIndex : 19 }}>
      <div className="flex pl-16 md:items-center md:pl-0">
        {showAlertIcon && (
          <div>
            <Icon
              height={20}
              width={20}
              name={'alert-circle'}
              fill={iconColor || '#946800'}
            />
          </div>
        )}
        <div className="ml-6 flex flex-col gap-2">
          <p
            className={`text-body-sm font-medium ${
              textColor
                ? textColor
                : 'text-npl-text-icon-on-light-surface-primary'
            }`}>
            {text}
          </p>
          {renderSubText && (
            <p className="text-body-sm text-npl-text-icon-on-light-surface-secondary">
              {renderSubText()}
            </p>
          )}
        </div>
      </div>
      {onClick && (
        <div className="px-8 md:ml-0 md:mt-0 md:px-12">
          <NPLLinkButton
            onClick={onClick}
            hierarchy={buttonHierarchy || LINK_BTN_HIERARCHY.ACCENT}
            size="sm"
            tailIcon="chevron-right"
            buttonText={ctaText}
          />
        </div>
      )}

      {onClose && (
        <button
          onClick={onClose}
          className="absolute right-12 top-12 md:right-16 md:top-16">
          <Icon name="x-close" height={16} width={16} />
        </button>
      )}
    </div>
  );
};

export default PortalBanner;
